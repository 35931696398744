export enum AppRoutes {
    Dashboard = '/dashboard',
    AuthMatchOtp = '/match-otp',
    Transaction = '/transaction',
    Events = '/events',
    Rsvp = '/rsvp',
    EventAddUpdate = '/events/add-update',
    ParticipantDetails = '',
    AdminMyProfile = '/my-profile',
    ChangePassword = '/my-profile/change-password',
    PaymentHistory = '/payments/history',
    Unauthorized = '/unauthorized',
    DataBaseRecords = '/database/records/list'
}